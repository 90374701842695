
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageComponent from './PreRealse/PageComponent.js'
import MenuBar from './PreRealse/MenuBar.js';
import ContactNavBar from './PreRealse/Contact.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Payment from './PreRealse/payment.js';

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MenuBar />} />
        <Route path="/PageComponent" element={<PageComponent />} /> 
        <Route path="/Contact" element={<ContactNavBar />} /> 
        <Route path="/Payment" element={<Payment />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
